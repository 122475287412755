import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
function Contact() {
	const [fullName, setFullName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	async function contactUs() {
		// convert the data to an object
		let item = { fullName, phoneNumber, email, message }
		console.log(item);
		// let result = await fetch("https://exeiqt.co.ke/exeiqtbackend/api/contact",
		let result = await fetch("http://localhost:8030/api/contact",
			{
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				}
			})
		result = await result.json()
		localStorage.setItem("user-info", JSON.stringify(result))
		setFullName("");
		setPhoneNumber("");
		setEmail("");
		setMessage("");
		setSuccessMessage("Message sent successfully.");
	}

	return (
		<>
			<div className="innerpage_banner">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h2>Contact us</h2>
						</div>
					</div>
				</div>
			</div>

			<div id="contact" className="contact-box">
				<div className="container">
					<div className="row">
						{successMessage && <div>
							<p className="text-success">
								{successMessage}
								<FontAwesomeIcon icon={faCheck} size="2x" color="green" />
							</p>
						</div>}
						<div className="col-lg-7 col-sm-7 col-xs-12">
							<div className="contact-block">

								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<input
												  
											/>
											<div className="help-block with-errors"></div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input
												type="text"
												placeholder="Your Email"
												id="email"
												className="form-control"
												name="email"
												value={email}
												required
												data-error="Please enter your email"
												onChange={(e) => setEmail(e.target.value)}
											/>
											<div className="help-block with-errors"></div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<input
												type="text"
												placeholder="Your number"
												id="phoneNumber"
												value={phoneNumber}
												className="form-control"
												name="phoneNumber"
												required
												data-error="Please enter your number"
												onChange={(e) => setPhoneNumber(e.target.value)}
											/>
											<div className="help-block with-errors"></div>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<textarea
												className="form-control"
												id="message"
												placeholder="Your Message"
												rows="8"
												value={message}
												data-error="Write your message"
												required
												onChange={(e) => setMessage(e.target.value)}
											></textarea>
											<div className="help-block with-errors"></div>
										</div>
										<div className="submit-button text-center">
											<button
												className="btn btn-common"
												onClick={contactUs}

											>
												Send Message
											</button>
											<div
												id="msgSubmit"
												className="h3 text-center hidden"
											></div>
											<div className="clearfix"></div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Contact;
