import React from "react";
import { Link } from 'react-router-dom';
import Select from 'react-select';

const Header = () => {

  const websiteLinks = [
    { value: 'Revision', label: 'Revision', url: 'https://www.ynetsolution.com/revision' },
    { value: 'Shop', label: 'Shop', url: 'https://www.shop.exeiqt.co.ke' },
    { value: 'Tickets', label: 'Tickets', url: 'https://www.ynetsolution.com/events' },
  ];
  const handleChange = (selectedOption) => {
    if (selectedOption.url) {
      window.open(selectedOption.url, '_blank');
    }
  };
  return (
    <>

      <div className="fixed-header header" id="header">
        <nav className="nav container">
          <Link to="/" className="nav__link text-decoration-none">
            <img class="logo" src="solution/images/logo.png" alt=""/>
          </Link>
          <div className="nav__menu" id="nav-menu">
            <ul className="nav__list">
              <li className="nav__item">
                <Link to="/" className="nav__link text-decoration-none">Home</Link>
              </li>
              <li className="nav__item">

                <Link to="aboutus" className="nav__link text-decoration-none">About Us</Link>

              </li>
              <li className="nav__item">

                <Link to="solutions" className="nav__link text-decoration-none">Solutions</Link>

              </li>

              <li className="nav__item">
                <Link to="https://ynetsolution.com/revision" target="_blank" className="nav__link text-decoration-none">Revision</Link>
              </li>
              <li className="nav__item">

                <Link to="contact" className="nav__link text-decoration-none">Contact</Link>

              </li>
              <li className="nav__item">
              <Select options={websiteLinks} onChange={handleChange} placeholder="More" />
            </li>

            </ul>

            <i className="ri-close-line nav__close" id="nav-close"></i>
          </div>

          <div className="nav__toggle" id="nav-toggle">
            <i className="ri-function-line"></i>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
