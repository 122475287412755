/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from 'react-router-dom';


const Banner = () => {
  return (
    <>
      <div class="section techwix-hero-section" style={{'background-image': 'url(solution/images/hero-bg1.png)'}}>
            <div class="shape-2"></div>
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <div class="hero-content">
                            <h3 class="sub-title" data-aos-delay="600" data-aos="fade-up"><span>HOT !</span> We can managed all digital services</h3>
                            <h2 class="title" data-aos="fade-up" data-aos-delay="800">Your partner for digital solutions</h2>
                            <p data-aos="fade-up" data-aos-delay="900">We provide the most responsive and functional IT design for companies and businesses worldwide.</p>
                            <Link to="/about-us" >
                              <button className="learn_more_btn mt-3">
                                Learn More
                              </button>
                            </Link>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="hero-images">
                            <div class="images">
                                <img src="assets/images/hero-img1.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Banner;
