import react from 'react';

const Features = () => {
    return (
        <>
            <div class="container-fluid gtco-features-list">
                <div class="container">
                    <div class="col-lg-6">
                        <h2 className='color-three'> Benefits of working with us: </h2>
                    </div>
                    <div class="row">
                        <div class="media col-md-6 col-lg-4">
                            <div class="oval mr-4"><img class="align-self-start" src="solution/images/quality-results.png" alt=""/></div>
                            <div class="media-body">
                                <h5 class="mb-0 color-three">Quality Results</h5>
                                <p className='text-dark'>
                                At Exeiqt, we believe that our success is directly
                                  tied to the success of our clients, which is why 
                                we are dedicated to providing top-notch service and exceptional results. 
                                </p>
                                
                            </div>
                        </div>
                        <div class="media col-md-6 col-lg-4">
                            <div class="oval mr-4"><img class="align-self-start" src="solution/images/analytics.png" alt=""/></div>
                            <div class="media-body">
                                <h5 class="mb-0 color-three">Analytics</h5>
                                <p className='text-dark'>
                                We work closely with our clients to identify their 
                                unique needs and goals and develop
                                 custom analytics solutions that can help them achieve those goals. 
                                </p>
                            </div>
                        </div>
                        <div class="media col-md-6 col-lg-4">
                            <div class="oval mr-4"><img class="align-self-start" src="solution/images/affordable-pricing.png" alt=""/></div>
                            <div class="media-body">
                                <h5 class="mb-0 color-three">Affordable Pricing</h5>
                                <p className='text-dark'>
                                A technology partner that offers 
                                affordable pricing without compromising on quality, look no
                                 further than Exeiqt.
                                </p>
                            </div>
                        </div>
                        <div class="media col-md-6 col-lg-4">
                            <div class="oval mr-4"><img class="align-self-start" src="solution/images/easy-to-use.png" alt=""/></div>
                            <div class="media-body">
                                <h5 class="mb-0 color-three">Easy To Use</h5>
                                <p className='text-dark'>
                                 We understand that user experience is key to the
                                 success of any project, which is why we make sure that our 
                                 software is intuitive and user-friendly.
                                </p>
                            </div>
                        </div>
                        <div class="media col-md-6 col-lg-4">
                            <div class="oval mr-4"><img class="align-self-start" src="solution/images/free-support.png" alt=""/></div>
                            <div class="media-body">
                                <h5 class="mb-0 color-three">Free Support</h5>
                                <p className='text-dark'>
                                Our free support services are available to you 24/7, 
                                so you can always count on us to be there when you need us. 
                                
                                </p>
                            </div>
                        </div>
                        <div class="media col-md-6 col-lg-4">
                            <div class="oval mr-4"><img class="align-self-start" src="solution/images/effectively-increase.png" alt=""/></div>
                            <div class="media-body">
                                <h5 class="mb-0 color-three">Effectively Increase</h5>
                                <p className='text-dark'>
                                We specialize in creating innovative solutions that
                                 can effectively increase
                                 your business's productivity, efficiency, and profitability. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Features;