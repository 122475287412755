/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

const About = () => {
  return (
    <>
      <section className="about section" id="about">
          <div className="container-fluid gtco-features" id="about">
            <div className="container">
                <div className="row">
                    <div class="col-lg-4">
                        <h2> Explore The Services<br/>
                            We Offer For You </h2>
                        <p className="color-three"> Would you like to work with us? </p>
                                <Link to="/contact" className="nav__link text-decoration-none">Request a quotation <i className="fa fa-angle-right" aria-hidden="true"></i></Link>

                        </div>
                    <div class="col-lg-8">
                        <svg id="bg-services"
                            width="100%"
                            viewBox="0 0 1000 800">
                            <defs>
                                <linearGradient id="PSgrad_02" x1="64.279%" x2="0%" y1="76.604%" y2="0%">
                                    <stop offset="0%" stop-color="rgb(1,230,248)" stop-opacity="1"/>
                                    <stop offset="100%" stop-color="rgb(29,62,222)" stop-opacity="1"/>
                                </linearGradient>
                            </defs>
                            <path fill-rule="evenodd" opacity="0.102" fill="url(#PSgrad_02)"
                                  d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z"/>
                        </svg>
                        <div className="row">
                            <div className="col">
                                <div className="card text-center">
                                    <div className="oval"><img class="card-img-top" src="solution/images/web-design.png" alt=""/></div>
                                    <div className="card-body">
                                        <h3 className="card-title">Web Development</h3>
                                        <p className="card-text color-three">Responsive and dynamic websites, both front-end and back-end. Also website made of wordpress</p>
                                    </div>
                                </div>
                                <div className="card text-center">
                                    <div className="oval"><img class="card-img-top" src="solution/images/marketing.png" alt=""/></div>
                                    <div className="card-body">
                                        <h3 className="card-title">Marketing</h3>
                                        <p className="card-text color-three ">Making the design of you product presentable to your customers.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card text-center">
                                    <div className="oval"><img class="card-img-top" src="solution/images/seo.png" alt=""/></div>
                                    <div className="card-body">
                                        <h3 className="card-title">Mobile Development</h3>
                                        <p className="card-text color-three">Building and desining of both android and IOS applications with full fanctionality</p>
                                    </div>
                                </div>
                                <div className="card text-center">
                                    <div className="oval"><img class="card-img-top" src="solution/images/graphics-design.png" alt=""/></div>
                                    <div className="card-body">
                                        <h3 className="card-title">Graphics Design</h3>
                                        <p className="card-text color-three">The art and practice of creating visual content to communicate messages, ideas, and information to an audience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </section>
    </>
  );
};
// Password: pn__RWc{+T!c
//   email: michaelndula@gmail.com
export default About;
