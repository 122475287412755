import React from "react";
import { Link } from "react-router-dom";
import "./Solutions.css"; // Import the CSS file

const Solutions = () => {
  return (
    <div className="main">
      <h2 className="main_heading">Our Solutions</h2>
      <ul className="cards">
        <li className="cards_item">
          <div className="card" tabIndex="0">
            <div className="card_image">
              <img src="assets/img/ynet.png" alt="YNET Solutions" />
            </div>
            <div className="card_content">
              <p className="card_title">YNET Solutions</p>
              <p className="card_description">
                Huge collection of Resources <strong>Explore Now</strong>
              </p>
              <Link to="https://ynetstudyhub.com/">
                <button className="upcharge">Visit Site</button>
              </Link>
            </div>
          </div>
        </li>
        <li className="cards_item">
          <div className="card" tabIndex="0">
            <div className="card_image">
              <img src="assets/img/shop.png" alt="Exeiqt Shop" />
            </div>
            <div className="card_content">
              <p className="card_title">Exeiqt Shop</p>
              <p className="card_description">
                Discover a huge collection of Resources <strong>Shop Now</strong>
              </p>
              <Link to="https://ynetsolution.com/">
                <button className="upcharge">Visit Site</button>
              </Link>
            </div>
          </div>
        </li>
        <li className="cards_item">
          <div className="card" tabIndex="0">
            <div className="card_image">
              <img src="assets/img/ynet.png" alt="Resources" />
            </div>
            <div className="card_content">
              <p className="card_title">Resources</p>
              <p className="card_description">
                Find valuable materials <strong>Start Learning</strong>
              </p>
              <Link to="https://ynetsolution.com/">
                <button className="upcharge">Visit Site</button>
              </Link>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Solutions;
