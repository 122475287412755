/* eslint-disable no-unused-vars */
import react from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

const WhatweOffer = () => {
    return (
        <>
            <div className="container-fluid gtco-banner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <img className="card-img-top img-fluid" src="solution/images/banner-img.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h1> We promise to bring
                                the best <span>solution</span> for
                                your business. </h1>
                            <p className='color-three'> 
                            When you choose us as your partner, you can trust that
                             we will be with you every step of the way, from initial 
                             concept to final implementation. We are passionate about
                              helping businesses succeed 
                            and are committed to providing the best possible service to our clients
                            </p>

              <Link to="/contact" className="nav__link text-decoration-none">Contact Us <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WhatweOffer;