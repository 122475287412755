/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const Projects = () => {
  return (
    <>
      <section class="pt-5 pb-5">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-3 solutions-title">Our Solutions </h3>
            </div>
            <div class="col-6 text-right">
              <a
                class="btn btn-primary mb-3 mr-1"
                href="#carouselExampleIndicators2"
                role="button"
                data-slide="prev"
              >
                <i class="fa fa-arrow-left"></i>
              </a>
              <a
                class="btn btn-primary mb-3 "
                href="#carouselExampleIndicators2"
                role="button"
                data-slide="next"
              >
                <i class="fa fa-arrow-right"></i>
              </a>
            </div>
            <div class="col-12">
              <div
                id="carouselExampleIndicators2"
                class="carousel slide"
                data-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                      <div class="col-md-4 mb-3">
                        <div class="card">
                          <img
                            class="img-fluid"
                            alt="100%x280"
                            src="assets/img/ynet.png"
                          />
                          <div class="card-body">
                            <h4 class="card-title">Ynet Revision Materials</h4>
                            <p class="card-text">
                            Download and access your favorite reads anytime, anywhere, on any device.
                            </p>
                            <a href="https://ynetsolution.com/revision" target="_blank" class="btn btn-primary" rel="noreferrer">Visit Site</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="card">
                          <img
                            class="img-fluid"
                            alt="100%x280"
                            src="assets/img/shop.png"
                          />
                          <div class="card-body">
                            <h4 class="card-title">Exeiqt Shop</h4>
                            <p class="card-text">
                              Get your favourite product at your comfort.
                            </p>
                            <a href="https://shop.exeiqt.co.ke" target="_blank" class="btn btn-primary" rel="noreferrer">Visit Site</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <div class="card">
                          <img
                            class="img-fluid"
                            alt="100%x280"
                            src="assets/img/ikwetu.png"
                          />
                          <div class="card-body">
                            <h4 class="card-title">Ikwetu</h4>
                            <p class="card-text">
                              Home far away from home, avoid house hunting.
                            </p>
                            <a href="https://ikwetu.com" target="_blank" class="btn btn-primary" rel="noreferrer">Visit Site</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
