import React from "react";
import Banner from "../components/Banner";
import About from "../components/About";
import Projects from "../components/Projects";
import WhatweOffer from "../components/WhatweOffer";
import Features from "../components/Features";

class Home extends React.Component {
  render() {
    return (
      <>
        <Banner />
        <About />
        <WhatweOffer />
        <Features />
        <Projects />
      </>
    );
  }
}
export default Home;
