import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';

export default function App() {
  return (
<>
  <div class="section techwix-cta-section-02">
      <div class="container">
          <div class="cta-wrap" style={{'background-image': 'url(solution/images/cta-bg.jpg)'}}>
              <div class="row align-items-center">
                  <div class="col-xl-9 col-lg-8">
                      <div class="cta-content">
                          <div class="cta-icon">
                          <img class="logo" src="solution/images/cta-icon2.png" alt=""/>
                          </div>
                          <p>We’re Delivering the best customer Experience</p>
                      </div>
                  </div>
                  <div class="col-xl-3 col-lg-4">
                      <div class="cta-btn">
                          <a class="btn btn-white" href="#">+2547 94316045</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="section footer-section footer-section-03" style={{'background-image': 'url(solution/images/footer-bg.jpg)'}}>

            <div class="container">
                <div class="footer-widget-wrap">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widget-about">
                                <a class="footer-logo" href="index.html"><img class="logo" src="solution/images/logo.png" alt=""/></a>
                                <p>Accelerate innovation with world-class tech teams We’ll match you to an entire remote team of incredible freelance talent.</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widget">
                                <h4 class="footer-widget-title">Useful Links</h4>

                                <div class="widget-link">
                                    <ul class="link">
                                        <li><a href="#">Terms & Conditions</a></li>
                                        <li><a href="#">About Company</a></li>
                                        <li><a href="#">Payment Gatway</a></li>
                                        <li><a href="#">Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widget">
                                <h4 class="footer-widget-title">Our Services</h4>

                                <div class="widget-link">
                                    <ul class="link">
                                        <li><a href="#">Data Security</a></li>
                                        <li><a href="#">IT Managment</a></li>
                                        <li><a href="#">Outsourcing</a></li>
                                        <li><a href="#">Networking</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widget">
                                <h4 class="footer-widget-title">Contact Information</h4>

                                <div class="widget-info">
                                    <ul>
                                        <li>
                                            <div class="info-icon">
                                                <i class="flaticon-phone-call"></i>
                                            </div>
                                            <div class="info-text">
                                                <span><a href="#">+91 458 654 528</a></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="info-icon">
                                                <i class="far fa-envelope-open"></i>
                                            </div>
                                            <div class="info-text">
                                                <span><a href="#">info@example.com</a></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="info-icon">
                                                <i class="flaticon-pin"></i>
                                            </div>
                                            <div class="info-text">
                                                <span>60 East 65th Street, NY</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MDBContainer className='p-4 pb-0'>
          <section className='mb-4'>
            <MDBBtn
              floating
              className='m-1'
              style={{ backgroundColor: '#3b5998' }}
              href='https://www.facebook.com/profile.php?id=100091964530114'
              target="_blank" rel="noopener noreferrer"
              role='button'
            >
              <MDBIcon fab icon='facebook-f' />
            </MDBBtn>

            <MDBBtn
              floating
              className='m-1'
              style={{ backgroundColor: '#55acee' }}
              href='https://twitter.com'
              target="_blank" rel="noopener noreferrer"
              role='button'
            >
              <MDBIcon fab icon='twitter' />
            </MDBBtn>

            <MDBBtn
              floating
              className='m-1'
              style={{ backgroundColor: '#ac2bac' }}
              href='https://www.secure.instagram.com/ynetsolutions/'
              target="_blank" rel="noopener noreferrer"
              role='button'
            >
              <MDBIcon fab icon='instagram' />
            </MDBBtn>

            <MDBBtn
              floating
              className='m-1'
              style={{ backgroundColor: '#0082ca' }}
              href='https://linkedin.com/in/exeiqt-company-57aa96268/'
              target="_blank" rel="noopener noreferrer"
              role='button'
            >
              <MDBIcon fab icon='linkedin-in' />
            </MDBBtn>

            <MDBBtn
              floating
              className='m-1'
              style={{ backgroundColor: '#333333' }}
              href=''
              target="_blank" rel="noopener noreferrer"
              role='button'
            >
              <MDBIcon fab icon='github' />
            </MDBBtn>
          </section>
        </MDBContainer>

            <div class="footer-copyright-area">
                <div class="container">
                    <div class="footer-copyright-wrap">
                        <div class="row align-items-center">
                            <div class="col-lg-12">
                            <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                              Copyright © 2023 :
                                <a className='text-reset fw-bold' href='https://ynetsolution.com/'>
                                  YNetSolutions
                                </a>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</>
    
  );
}
