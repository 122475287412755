import react from 'react';

const Services = () => {
    return (
        <>
            <div class="innerpage_banner">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Our Services</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section layout_padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="full center">
                                <div class="heading_main text_align_center">
                                    <h2 className='color-four'><span class="theme_color">What </span>we do</h2>
                                    <p class="large"> Work with the Best | Get the Best</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="heading_main text_align_center mt-4 mb-4">
                        <p className='text-dark '>At Exeiqt, we offer a wide range of technology services that
                            are designed to help our clients grow and succeed. Whether you
                            need help with app development, website design,
                            or system implementation, our team of experts is here to help.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Services;