/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const AboutUs = () => {
    return (
        <>
            <div class="innerpage_banner">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>About Us</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section layout_padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="full center">
                                <div class="heading_main text_align_center">
                                    <h2><span class="theme_color">About </span>Us</h2>
                                    <p class="large"> Work with the Best | Get the Best</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-xxl py-5">
                <div class="container px-lg-5">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="section-title position-relative mb-4 pb-2">
                                <h6 class="position-relative text-primary ps-4">About Us</h6>
                                <h2 className="mt-2 color-four">The Web and App dev with Enough experience</h2>
                            </div>
                            <p className="mb-4 text-dark">
                                Welcome to Exeiqt, a technology company with a passion for turning
                                your dreams and visions into reality. We believe that technology
                                can be a powerful tool for unlocking new opportunities and driving
                                growth, which is why we are dedicated to helping our clients stay
                                ahead of the curve.
                            </p>
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Good Design</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                                </div>
                                <div class="col-sm-6">
                                    <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                                    <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mt-4">
                                <a class="btn btn-primary rounded-pill px-4 me-3" href="">Read More</a>
                                <a class="btn btn-outline-primary btn-rounded me-3" href='https://www.facebook.com/profile.php?id=100091964530114'
                                    target="_blank"><i class="fab fa-facebook-f"></i>
                                </a>
                                <a class="btn btn-outline-primary btn-rounded me-3" href=""><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-outline-primary btn-rounded me-3" href="https://www.secure.instagram.com/exeiqt/"><i class="fab fa-instagram"></i></a>
                                <a class="btn btn-outline-primary btn-rounded" href=""><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <img alt='' class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="about-us/img/about.jpg" />
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default AboutUs;