import React from 'react'
import { BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './components/Home'
import AboutUs from './components/AboutUs'
import Contact from './components/Contact'
import Services from './components/Services'
import Solutions from './solutions/Solutions'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import SplineTest from './spline/Spline'

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="solutions" element={<Solutions />} />
        <Route path="contact" element={<Contact />} />
        <Route path="services" element={<Services />} />
        <Route path="spline" element={<SplineTest />} />
      </Routes>
      <Footer />
      <Helmet>
        <script type="text/javascript" src="./assets/js/scrollreveal.min.js"></script>
        <script type="text/javascript" src="./assets/js/swiper-bundle.min.js"></script>
        <script type="text/javascript" src="./assets/js/main.js"></script>
        <script src="solution/js/jquery-3.3.1.slim.min.js"></script>
        <script src="solution/js/popper.min.js"></script>
        <script src="solution/js/bootstrap.min.js"></script>
        <script src="solution/owl-carousel/owl.carousel.min.js"></script>
        <script src="solution/js/main.js"></script>
        <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"></script>
        <script src="about-us/lib/wow/wow.min.js"></script>
        <script src="about-us/lib/easing/easing.min.js"></script>
        <script src="about-us/lib/waypoints/waypoints.min.js"></script>
        <script src="about-us/lib/owlcarousel/owl.carousel.min.js"></script>
        <script src="about-us/lib/isotope/isotope.pkgd.min.js"></script>
        <script src="about-us/lib/lightbox/js/lightbox.min.js"></script>
        <script src="about-us/js/main.js"></script>
      </Helmet>
    </>
  )
}

export default App
